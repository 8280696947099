@font-face {
  font-family: 'MetaOT';
  src: local('MetaOT'), url('./assets/fonts/MetaOT.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'MetaOTBold';

  src: local('MetaOT-Bold'), url('./assets/fonts/MetaOT-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'FFMeta';

  src: local('FFMeta'), url('./assets/fonts/MetaW05-Light.woff2') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'FFMeta';

  src: local('FFMeta'), url('./assets/fonts/MetaW05-Regular.woff2') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'FFMeta';

  src: local('FFMeta'), url('./assets/fonts/MetaW05-Medium.woff2') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'FFMeta';

  src: local('FFMeta'), url('./assets/fonts/MetaW05-Bold.woff2') format('truetype');
  font-weight: 1000;
}

* {
  font-family: 'MetaOT';
  color: #000000;
}

b,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'MetaOTBold';
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#onetrust-banner-sdk.otFloatingRoundedIcon {
  z-index: 110 !important;
}

#onetrust-banner-sdk.otFloatingRoundedIcon.default {
  right: 5em;
  margin: auto;
}

body > div {
  padding-right: 0 !important;
}

iframe {
  z-index: 101 !important;
}
